@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
.line {
  @apply w-full  border-[#F5F3F2] lg:border-b-[6px] border-b-4;
}

.input {
  @apply p-3 w-full text-sm text-gray-27 rounded bg-gray-f2 border border-gray-e8 outline-none placeholder:capitalize focus:bg-white focus:border-blue-primary focus:caret-blue-primary;
}

.link {
  @apply capitalize text-blue-primary text-sm cursor-pointer hover:font-semibold;
}

.search-filter__input {
  @apply py-3 px-4 text-sm text-black41 rounded border border-gray-df bg-gray-f6 placeholder:capitalize hover:bg-white focus:bg-white focus:border-blue-primary focus:caret-blue-primary;
}

.input__error-message {
  @apply text-xs text-red-primary first-letter:uppercase;
}

.page {
  @apply relative h-screen grid place-items-center;
}

.backdrop {
  @apply w-screen h-screen bg-gray-4147 grid place-items-center fixed top-0 left-0 z-30 cursor-pointer;
}

.dashboard-card__list-item {
  @apply capitalize py-1 pl-3 pr-8 text-sm text-black41 cursor-pointer hover:bg-gray-f5;
}

.alignment {
  @apply w-95/100 max-w-7xl mx-auto;
}

.map__info-window__list-item {
  @apply text-11px text-gray-70 capitalize my-1;
}

.clinic__menu-item {
  @apply flex items-center py-2 px-2 text-sm;
}

.table__cell {
  @apply bg-white px-3 py-5 whitespace-nowrap;
}

.pagination-button {
  @apply w-6 h-6 rounded text-gray-7e duration-500 cursor-pointer;
}

.pagination-button:disabled {
  @apply cursor-not-allowed;
}

.pagination-button:hover:enabled {
  @apply bg-blue-primary text-white;
}

.table__checkbox {
  @apply w-[18px] h-[18px] bg-white border-2 border-gray-e8 rounded cursor-pointer;
}
@tailwind utilities;

html {
  background: #F2F0EE;
  font-family: "Open Sans", sans-serif;
  scrollbar-color: #e4e6ef transparent;
  scrollbar-width: thin;
}

.font-proxima {
  font-family: 'Proxima Nova', sans-serif;
}

.td-firefox {
  background-clip: padding-box;
}

/*Scrollbar Design*/
.scroll-firefox {
  scrollbar-color: #e4e6ef transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e4e6ef;
  border-radius: 25px;
  border: 3px transparent solid;
  background-clip: padding-box;
}
/*Scrollbar Design*/

/*DatePicker Design*/
.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
  z-index: 100;
}
/*DatePicker Design*/

.react-datepicker-wrapper {
  width: 100%;
}

.clinic__modal {
  max-height: calc(100vh - 32px);
  overflow-y: auto;
}

/*Google Map InfoWindow Design*/
.gm-style .gm-style-iw-t::after {
  background: none;
  box-shadow: none;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.grecaptcha-badge {
  opacity: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.sticky-pagination {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
